import React from 'react';
import logo from './logo.svg';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'; // v1.x
import { MuiThemeProvider as V0MuiThemeProvider} from 'material-ui';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import 'typeface-roboto';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
//import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import CreateUser from "./components/create-user.component";
import Users from "./components/users.component";
import Usuarios from "./components/usuarios.component";
import Notas from "./components/notas.component";
import Blog from "./components/blog.component";
import Traducciones from "./components/traducciones.component";
import Traduccionesdos from "./components/traduccionesdos.component";
import Lenguaje from "./components/lenguaje.component";
import { TranslatorProvider, useTranslate } from "react-translate"
import Header from "./components/header.component";
import Menu from "./components/menu.component";

import PropTypes from 'prop-types';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
  } from 'react-switch-lang';
import en from './en.json';
import th from './th.json';
import es from './es.json';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

setTranslations({ en, th, es });
//setTranslations({ th, es });
//setDefaultLanguage('en');
setDefaultLanguage('es');
 
// If you want to remember selected language
setLanguageCookie();



let translations = {
  locale: "en",
  Home: {
    "HELLO": "Helloworld!"
  }
};
 


function formatDate(date) {
  return date.toLocaleDateString();
}

function Comment(props) {
  return (
    <div className="Comment">
      <div className="UserInfo">
        <img
          className="Avatar"
          src={props.author.avatarUrl}
          alt={props.author.name}
        />
        <div className="UserInfo-name">
          {props.author.name}
        </div>
      </div>
      <div className="Comment-text">{props.text}</div>
      <div className="Comment-date">
        {formatDate(props.date)}
      </div>
    </div>
  );
}

const comment = {
  date: new Date(),
  text: 'I hope you enjoy learning React!',
  author: {
    name: 'Hello Kitty',
    avatarUrl: 'https://placekitten.com/g/64/64',
  },
};

const theme = createMuiTheme({
  /* theme for v1.x */
 });
 const themeV0 = getMuiTheme({
  /* theme for v0.x */
 });
 /*
handleSetLanguage = (key) => () => {
  setLanguage(key);
};
*/


function Welcome(props) {
  return <h1>Hello, {props.name}</h1>;
}
function App() {

  
  /*
  handleSetLanguage = (key) => () => {
      setLanguage(key);
  };
  */
  
  return (
    <TranslatorProvider translations={translations}>
    <Router>
      <Menu></Menu>
      <div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/topics">Topics</Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to={"/create-user"}>Create User</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/users"}>Users List</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/usuarios"}>Usuarios</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/notas"}>Notas</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/blog"}>Blog</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/traducciones"}>Traducciones</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/traduccionesdos"}>Traducciones dos</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/lenguaje"}>Lenguaje</Link>
          </li>
        </ul>

        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/topics">
            <Topics />
            <Home />
            <CreateUser />
          </Route>
          <Route path="/users" component={Users}>
            
          </Route>
          <Route path="/usuarios" component={Usuarios}>
            
          </Route>
          <Route path="/notas" component={Notas}>
            
          </Route>
          <Route path="/blog" component={Blog}>
            
          </Route>
          <Route path="/traducciones" component={Traducciones}>
            
          </Route>
          <Route path="/traduccionesdos" component={Traduccionesdos}>
            
          </Route>
          <Route path="/lenguaje" component={Lenguaje}>
            
          </Route>
          <Route path="/create-user" component={CreateUser} />
          <Route path="/">
            <Home />
          </Route>
          
          

          
          
        </Switch>
      </div>
    </Router>
    </TranslatorProvider>
    
  );
}

export default App;
/*

<Route path="/create-user" component={CreateUser} />

          
          <Route path="/users" component={Users} />


<div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Héctor Aguilar
        </p>

        <div>
          <Welcome name="Sara" />
          <Welcome name="Cahal" />
          <Welcome name="Edite" />
        </div>
        
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>

        <Comment
          date={comment.date}
          text={comment.text}
          author={comment.author}
        />



          




          <CircularProgress variant="indeterminate" />

          <Icon>home</Icon>


          <Button variant="contained" />


          <AddIcon />




          

          


      </header>

    </div>





<Typography variant="h1" component="h2">
  h1. Heading
</Typography>

<MuiThemeProvider theme={theme}>
     <V0MuiThemeProvider muiTheme={themeV0}>
       {Components}
       </V0MuiThemeProvider>
       </MuiThemeProvider>




       <Switch
            checked={this.state.checkedA}
            onChange={this.handleSwitch}
          />



          <AddIcon />



          <Select value={this.state.value}></Select>
*/
function Home() {
  
  let t = useTranslate("Home");
  return <h1>HOME QUE SHOW {t("HELLO")} </h1>
  //return <h2>Home</h2>;
}

function About() {

  
  /*
  state = {
    loading: true,
    error: "",
    data: null
  };
  */

  var params = {
      data1: 'holamundo',
  }

  //var url = "https://jsonplaceholder.typicode.com/posts";
  //var url = "http://aguitech.casidios.com/panel/ws_notas.php";
  var url = "http://aguitech.casidios.com/panel/ws_notas_react.php";
  var resultados = [];
  axios.post(url, params).then(function(response) {
      //code here 

      
      console.log("response")
      console.log(response)

      console.log(response["data"])

      console.log(response["data"].resultado)

      console.log(response["data"].resultado[0])

      console.log(response["data"].resultado.length)

      console.log(response["data"].resultado[0]["id_nota"])

      for(var i = 0; i < response["data"].resultado.length; i++){
        console.log(i);
        console.log(response["data"].resultado[i]["id_nota"]);
        console.log(response["data"].resultado[i]["nota"]);
      }
      //console.log(response["data"].resultado[0].nota)

      //var resultados = response["data"].resultado;
      resultados = response["data"].resultado;

      //this.resultados = response["data"].resultado;

      console.log(resultados);

      /*
      this.setState({
        data: resultados,
        loading: false,
        error: false
      });
      */

      
      /*
      this.setState({
        posts: res.data,
        loading: false,
      });

      this.state = {
        posts: [],
        loading: true,
      };
      */

      

  });


  /*

    axios({
      method: 'post', 
      url: 'https://jsonplaceholder.typicode.com/posts',
      //http://aguitech.casidios.com/panel/ws_notas.php
      //url: 'https://aguitech.casidios.com/panel/ws_notas.php',
      data: {
        hola:"otro"
        //api_data
      }
    })
    .then(function (response) {
      console.log(response);
      //self.setState({events: response.data})
    })
  .catch(function (error) {
      console.log(error);
  });
  */


  /*
    .then( response => {

      console.log(response)
      return ({
        //type    : FETCH_CPS,
        //payload : response.data
      });
    } ).catch( ( error ) => {
      console.log( error );
    } )
    */

    console.log("Resultados");
    //console.log(this.resultados);

    
    const data = resultados;

    const datas = [
      
        "www.google.com",
        "Click here and enjoy searching",
        "17"
      
    ];

    //const datas2;
    //datas2 = [
    const datas2 = [
      
      "www.google.com",
      "Click here and enjoy searching",
      "Hector"
    
    ];

    const hello = "HOLA QUE ONDA";
  //const data = resultados;
  /*
  const data = [
    {
      "0": "www.google.com",
      "1": "Click here and enjoy searching",
      "2": "17"
    },
  ];
  */

  
  return (
    
    <div>
      <h2>About</h2>
      {hello}
      {datas}
      <div className="App">
        <Menu></Menu>
        <Header></Header>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Héctor Aguilar
          </p>

          <div>
            <Welcome name="Sara" />
            <Welcome name="Cahal" />
            <Welcome name="Edite" />
          </div>
          
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>

          <Comment
            date={comment.date}
            text={comment.text}
            author={comment.author}
          />


          <div>
            (resultados)
            {resultados}

            DATA
            {data}

            {data.map(item => {
              return <div>{item[0]}</div>;
              //return "hola"
            })}

            {resultados.map(item => {
              return <div>{item[0]["id_nota"]}</div>;
              //return "hola"
            })}

            {datas.map(item => {
              return <div>{item}</div>;
              //return "hola"
            })}



            {datas2.map(item => {
              return <div>{item}</div>;
              //return "hola"
            })}

          </div>



            




            <CircularProgress variant="indeterminate" />

            <Icon>home</Icon>


            <Button variant="contained" />


            <AddIcon />




            

            


        </header>

      </div>
    </div>
  );
}

function Topics() {
  let match = useRouteMatch();

  return (
    <div>
      <Header></Header>
      <h2>Topics</h2>

      <ul>
        <li>
          <Link to={`${match.url}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${match.url}/props-v-state`}>
            Props v. State
          </Link>
        </li>
      </ul>

      {/* The Topics page has its own <Switch> with more routes
          that build on the /topics URL path. You can think of the
          2nd <Route> here as an "index" page for all topics, or
          the page that is shown when no topic is selected */}
      <Switch>
        <Route path={`${match.path}/:topicId`}>
          <Topic />
        </Route>
        <Route path={match.path}>
          <h3>Please select a topic.</h3>
        </Route>
      </Switch>
    </div>
  );
}

function Topic() {
  let { topicId } = useParams();
  return <h3>Requested topic ID: {topicId}</h3>;
}
/*

          <Route exact path='/' component={CreateUser} />
          
*/